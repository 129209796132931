import { cx } from 'class-variance-authority';
import { MouseEventHandler } from 'react';
import type { ButtonGroupProps } from 'react-multi-carousel';

import Image, { type ImageProps } from '../Image';
import { ChangeSlide } from './types';

export interface CarouselButtonsProps extends ButtonGroupProps {
  readonly images: ImageProps[];
  readonly onChange: ChangeSlide;
}

const CarouselButtons = ({
  carouselState,
  images,
  goToSlide,
  onChange,
}: CarouselButtonsProps): JSX.Element => {
  const currentSlideIndex = carouselState?.currentSlide;

  const handleChange: MouseEventHandler<HTMLButtonElement> = ({
    currentTarget,
  }) => {
    const nextSlideIndex = Number(currentTarget.value);
    onChange(nextSlideIndex);
    goToSlide?.(nextSlideIndex);
  };

  return (
    <div
      data-testid="image-carousel-buttons"
      className="flex justify-center gap-2 p-4"
    >
      {images.map(({ alt, src }, idx: number) => {
        const isSelected = currentSlideIndex === idx;

        return (
          <button
            key={`carousel-button-${alt}-${idx}`}
            data-selected={currentSlideIndex === idx}
            aria-current={isSelected}
            className={cx(
              'w-14 h-14 grow-0 shrink-0',
              'overflow-hidden rounded bg-white',
              'transition-all',
              'focus:z-100', // show focus outline on top of other buttons
            )}
            type="button"
            value={idx}
            onClick={handleChange}
          >
            <div
              className={cx(
                'flex justify-center',
                'hover:opacity-100 focus:bg-opacity-100',
                {
                  'opacity-100': isSelected,
                  'opacity-50': !isSelected,
                },
              )}
            >
              <Image priority alt={alt} src={src} width={56} height={56} />
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default CarouselButtons;
