/*Hydrates state post-auth & routes user to correct next step */

import { PartnerConfig } from '@/configs/partner';
import { accountAdded, emailAdded } from '@/lib/account/accountSlice';
import { orderAdded } from '@/lib/order/orderSlice';

import { getEmailToken } from './emailTokenHelper';
import { fetchSessionData } from './fetchSessionData';

export const handleNewSession = async (
  email: string,
  partner: string,
  partnerConfig: PartnerConfig,
  dispatch: (action: any) => void,
  push: (path: string) => void,
) => {
  const emailToken = getEmailToken();
  const response = await fetchSessionData(
    email,
    emailToken,
    partner,
    partnerConfig,
  );

  if (response.error) {
    push(partnerConfig.invalidStep);
  }

  if (response.data) {
    dispatch(emailAdded(email));

    if (response.data.order?.lineItems?.length) {
      dispatch(orderAdded(response.data.order!));
    }

    if (response.data.account?.orders?.length) {
      dispatch(accountAdded(response.data.account));
    }
    const targetRoute = response.data.order?.orderComplete
      ? partnerConfig.completedStep
      : partnerConfig.successStep;
    push(targetRoute);
  }
};
