import { Alert } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';

import { CheckStatus } from '../../ui/EmailForm/const';

interface EmailStatusProps {
  readonly checkStatus: CheckStatus;
}

const EmailStatus = ({ checkStatus }: EmailStatusProps): JSX.Element => {
  const t = useTranslations();

  if (checkStatus === CheckStatus.EXPIRED) {
    return (
      <Alert data-testid="expired-session-info" severity="info">
        {t('my_account_expired_session')}
      </Alert>
    );
  }

  if (checkStatus === CheckStatus.ERROR) {
    return (
      // only show the check email message to prevent bad actors from using it to harvest customer email addresses from it
      <Alert data-testid="alert-fail" severity="error">
        {t('my_account_email_error')}
      </Alert>
    );
  }

  return (
    <Alert data-testid="alert-success" severity="success">
      {t('eop_login_email_page_otp_sent_text')}
    </Alert>
  );
};

export default EmailStatus;
