import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { useTranslations } from 'next-intl';

import {
  findAllVariants,
  findImageAsset,
  getProductFinish,
  getProductVariantSize,
} from '@/app/utils';
import type { Product } from '@/queries/types/graphql';

import type { FinishOption, SizeOption } from './types';

interface ProductOptionsData {
  finishOptions: FinishOption[];
  sizeOptions: SizeOption[];
}

type ImageType = 'selector' | 'angle';

interface UseProductOptionDataOptions {
  selectedSku?: string;
  imageType?: ImageType;
}

export const getOptionImgSrc = (
  product: Product,
  imageType?: ImageType,
): string => {
  if (imageType === 'selector') {
    return (
      findImageAsset(product, 'finish-selector') ??
      findImageAsset(product, 'angle') ??
      ''
    );
  }

  return (
    findImageAsset(product, 'angle') ??
    // TODO: remove once Gen3 is extinct
    product.images?.[1]?.originalSrc ??
    ''
  );
};

export const useFinishOptions = (
  products: Product[],
  options: UseProductOptionDataOptions = {},
) => {
  const t = useTranslations();
  const { imageType } = options;

  return products.reduce((list, product) => {
    const finish = getProductFinish(product);

    if (!finish) return list;

    // if finish is already in list, skip it
    if (list.some((item) => item.value === finish)) {
      return list;
    }

    return [
      ...list,
      {
        imgSrc: getOptionImgSrc(product, imageType),
        label: t(product.colorMsgKey),
        value: finish,
      },
    ];
  }, [] as FinishOption[]);
};

export const useSizeOptions = (
  products: Product[],
  options: UseProductOptionDataOptions = {},
): SizeOption[] => {
  const { selectedSku } = options;
  const variants = findAllVariants(products, selectedSku);

  const allSizes: SizeOption[] = variants
    ?.map((variant) => getProductVariantSize(variant))
    // filter out any bad sizes
    .filter((size) => size !== undefined)
    .filter((size) => !['Size later'].includes(size))
    .map((size) => ({
      value: size,
      label: size?.startsWith('0') ? size.replace('0', '') : size,
    }));

  const uniqueOptions = uniqBy(allSizes, 'value');
  const sortedOptions = sortBy(uniqueOptions, ({ value }) => parseInt(value));

  return sortedOptions as SizeOption[];
};

const useProductOptionsData = (
  products: Product[],
  { selectedSku, imageType }: UseProductOptionDataOptions = {},
): ProductOptionsData => {
  const finishOptions = useFinishOptions(products, { imageType });
  const sizeOptions = useSizeOptions(products, { selectedSku });

  return {
    finishOptions,
    sizeOptions,
  };
};

export default useProductOptionsData;
