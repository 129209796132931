import {
  RingFinishChoices,
  RingFinishData,
  SizeChoices,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useState } from 'react';

import { findImageAsset, findProductDataBySku, findSku } from '@/app/utils';
import { Product } from '@/queries/types/graphql';
import { OrderItem } from '@/types/Order';

import Note from '../../Note';
import SectionHeading from '../../SectionHeading';
import { SizeGuide } from '../../SizeGuide';
import useProductOptionsData from '../useProductOptionsData';
import FinishOptions from './FinishOptions';

const INIT = {
  FINISH: '',
  SIZE: '',
};

export interface SingleRingProps {
  readonly className?: string;
  readonly sizeError: string;
  readonly onSelect: (sku: OrderItem['sku']) => void;
  readonly products: Product[];
  readonly selectedSku: string | undefined;
}

const SingleRing = ({
  className,
  sizeError,
  selectedSku,
  onSelect,
  products,
}: SingleRingProps) => {
  const t = useTranslations();

  const { finishOptions, sizeOptions } = useProductOptionsData(products, {
    selectedSku,
    imageType: 'selector',
  });

  // we can use the new RingFinishChoices if all rings have selector image assets
  const hasSelectorImages = products.every((product) =>
    findImageAsset(product, 'finish-selector'),
  );

  const { finish: initFinish = INIT.FINISH, size: initSize = INIT.SIZE } =
    findProductDataBySku(products, selectedSku);

  const [selectedFinish, setSelectedFinish] = useState<string>(initFinish);
  const [selectedSize, setSelectedSize] = useState<string>(initSize);

  const handleChange = useCallback(
    (finish: string, size: string) => {
      // fire onSelect when selection changes
      const sku = findSku(products, finish, size);

      if (sku !== selectedSku) {
        onSelect(sku);
      }
    },
    [onSelect, products, selectedSku],
  );

  const handleChangeFinish = useCallback(
    (newFinish: string) => {
      handleChange(newFinish, selectedSize);
      setSelectedFinish(newFinish);
    },
    [handleChange, selectedSize],
  );

  const handleChangeSize = useCallback(
    (newSize: string) => {
      handleChange(selectedFinish, newSize);
      setSelectedSize(newSize);
    },
    [handleChange, selectedFinish],
  );

  useEffect(() => {
    // set initial finish option (once products load)
    if (
      finishOptions.length &&
      !finishOptions.some(({ value }) => value === selectedFinish)
    ) {
      const finish = finishOptions.some(({ value }) => value === INIT.FINISH)
        ? INIT.FINISH
        : finishOptions[0].value;

      handleChange(finish, selectedSize);
      setSelectedFinish(finish);
    }
  }, [finishOptions, handleChange, selectedFinish, selectedSize]);

  useEffect(() => {
    // reset selected size when not found in list of options
    if (!sizeOptions?.some(({ value }) => value === selectedSize)) {
      handleChange(selectedFinish, selectedSize);
      setSelectedSize(INIT.SIZE);
    }
  }, [handleChange, selectedFinish, selectedSize, sizeOptions]);

  return (
    <div data-testid="product-select-single-ring" className={className}>
      <section className="flex flex-col gap-4">
        <SectionHeading id="finish-options-heading">
          {t('eop_order_selections_page_choose_finish')}
        </SectionHeading>

        {hasSelectorImages ? (
          initFinish && ( // hide until initFinish is set (TODO: refactor to force re-render)
            <RingFinishChoices
              data-testid="finish-options"
              initialSelectedFinish={initFinish}
              ringFinishesData={
                finishOptions.map((opt) => ({
                  alt: opt.label,
                  color: opt.label,
                  finish: opt.value,
                  src: opt.imgSrc,
                })) as RingFinishData[]
              }
              onChange={handleChangeFinish}
              aria-labelledby="finish-options-heading"
              aria-required="true"
            />
          )
        ) : (
          // TODO: remove this once Gen3 are extinct
          <FinishOptions
            onChange={handleChangeFinish}
            options={finishOptions}
            selected={selectedFinish}
          />
        )}
      </section>

      <section className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div>
            <SectionHeading id="size-options-heading">
              {t('eop_order_selections_page_choose_size')}
            </SectionHeading>

            {sizeError && (
              <Typography color="error" variant="body-small">
                {sizeError}
              </Typography>
            )}
          </div>

          <Note>{t('eop_order_selections_page_choose_size_description')}</Note>

          <SizeGuide />
        </div>

        <SizeChoices
          data-testid="size-options"
          onChange={handleChangeSize}
          ringSizesData={sizeOptions.map((size) => ({
            size: parseInt(size.value),
          }))}
          initialSelectedSize={parseInt(selectedSize)}
          aria-errormessage={sizeError}
          aria-invalid={Boolean(sizeError)}
          aria-required="true"
          aria-labelledby="size-options-heading"
          label={''} // TODO: remove after removing default from ecom-components
        />
      </section>
    </div>
  );
};

export default SingleRing;
