import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { OrderItem } from '@/types/Order';

import Note from '../../Note';
import SectionHeading from '../../SectionHeading';
import { SizeGuide } from '../../SizeGuide';
import RingItem, { MultiRingItem, RingItemProps } from './RingItem';
import updateItems from './updateItems';

export interface MultiRingProps {
  readonly className?: string;
  readonly products: RingItemProps['products'];
  readonly lineItems?: OrderItem[];
  readonly onChange: (items: OrderItem[]) => void;
}

const MultiRing = ({
  className,
  products,
  lineItems,
  onChange,
}: MultiRingProps): JSX.Element => {
  const t = useTranslations();

  // add an "id" to each lineItem, for reference
  const itemsList = useMemo(
    () => lineItems?.map((item, id) => ({ ...item, id })) ?? [],
    [lineItems],
  );

  const handleChange = (updatedItem: MultiRingItem) => {
    const updatedItems = updateItems(itemsList, updatedItem);
    onChange(updatedItems);
  };

  return (
    <div data-testid="product-select-multi-ring" className={className}>
      <div className="flex flex-col gap-2">
        <SectionHeading id="size-options-heading">
          {t('sizing_tips')}
        </SectionHeading>

        <Note>{t('eop_order_selections_page_choose_size_description')}</Note>

        <SizeGuide />
      </div>

      <ul>
        {itemsList.map((item, idx) => (
          <li
            key={item.id}
            className={cx('py-6', {
              'border-t border-gray-300': idx,
            })}
          >
            <RingItem
              item={item}
              products={products}
              onChange={(updatedItem: MultiRingItem) => {
                handleChange(updatedItem);
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MultiRing;
