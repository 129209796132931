'use client';

import { GridItem, LayoutGrid } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

import { page } from '@/app/styles/ui';
import { Product } from '@/queries/types/graphql';
import { OrderItem } from '@/types/Order';

import { ImageCarousel } from '../../ui';
import PageHeader, { type PageHeaderProps } from '../PageHeader';
import useProductCarouselImages from './useProductCarouselImages';

export interface ProductLayoutProps {
  readonly eyebrow?: PageHeaderProps['eyebrow'];
  readonly lineItems?: OrderItem[];
  readonly note?: PageHeaderProps['note'];
  readonly products: Product[];
  readonly title: PageHeaderProps['title'];
}

export const ProductLayout = ({
  eyebrow,
  note,
  title,
  children,
  products,
  lineItems,
}: PropsWithChildren<ProductLayoutProps>): JSX.Element => {
  const images = useProductCarouselImages(products, { lineItems });

  return (
    <LayoutGrid
      data-testid="product-layout"
      rowGap={24}
      className={cx(page(), 'pt-0 md:pt-10')}
    >
      <GridItem
        colEnd={{ sm: 'main', xl: 23 }}
        colStart={{ sm: 'main', md: 13 }}
        rowEnd={{ sm: 3, md: 2 }}
        rowStart={{ sm: 2, md: 1 }}
      >
        <PageHeader
          className="mb-2"
          eyebrow={eyebrow}
          title={title}
          note={note}
        />
      </GridItem>

      <GridItem
        colEnd={{ sm: 'full', md: 12 }}
        colStart={{ sm: 'full', md: 'main', xl: 3 }}
        rowEnd={{ sm: 2, md: 3 }}
        rowStart={{ sm: 1 }}
      >
        <ImageCarousel images={images} />
      </GridItem>

      <GridItem
        colEnd={{ sm: 'main', xl: 23 }}
        colStart={{ sm: 'main', md: 13 }}
        rowEnd={{ sm: 4, md: 3 }}
        rowStart={{ sm: 3, md: 2 }}
      >
        {children}
      </GridItem>
    </LayoutGrid>
  );
};

export default ProductLayout;
