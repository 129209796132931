'use client';

import {
  TypographyV3 as Typography,
  TypographyV3Props as TypographyProps,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { ReactNode, useMemo } from 'react';

const DEFAULT_HELP_EMAIL = 'partnersupport@ouraring.com';

export interface HelpEmailLinkProps extends Omit<TypographyProps, 'children'> {
  readonly standalone?: boolean;
  readonly helpEmail?: string;
}

const HelpEmailLink = ({
  standalone: isStandalone,
  helpEmail,
  className,
  ...typographyProps
}: HelpEmailLinkProps): JSX.Element => {
  const t = useTranslations();
  const email = helpEmail ? helpEmail : DEFAULT_HELP_EMAIL;

  // use different MessageKey based on props
  const emailText: ReactNode = useMemo(() => {
    if (isStandalone) {
      // Need help? Email partnersupport@ouraring.com
      return t.rich('eop_login_email_page_support_text', { helpEmail: email });
    }
    // Email partnersupport@ouraring.com for assistance.
    return t.rich('eop_need_additional_help_text', { helpEmail: email });
  }, [isStandalone]);

  return (
    <Typography
      data-testid="help-email-link"
      className={cx(className, {
        'flex flex-wrap gap-x-1.5': isStandalone,
      })}
      {...typographyProps}
    >
      {emailText}
    </Typography>
  );
};

export default HelpEmailLink;
