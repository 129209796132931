export const PUBLIC_ROUTES = ['/', '/email', '/404', '/maintenance'];

export interface PartnerConfig {
  validateUser: boolean;
  getOrders: boolean;
  invalidStep: string;
  successStep: string;
  completedStep: string;
  publicRoutes: string[];
  overrideLocale?: string;
  supportEmail?: string;
  // Routes to ensure new data is fetched
  fetchDataRoutes?: string[];
}

export const config: Record<string, PartnerConfig> = {
  demo: {
    validateUser: false,
    getOrders: false,
    invalidStep: '/',
    successStep: '/',
    completedStep: '/',
    publicRoutes: PUBLIC_ROUTES,
  },
  essence: {
    validateUser: true,
    getOrders: false,
    invalidStep: '/invalid-email',
    successStep: '/select',
    completedStep: '/summary',
    overrideLocale: 'en',
    supportEmail: 'partnersupport@ouraring.com',
    fetchDataRoutes: ['/select'],
    publicRoutes: PUBLIC_ROUTES,
  },
  business: {
    validateUser: false,
    getOrders: true,
    invalidStep: '/invalid-email',
    successStep: '/account',
    completedStep: '/summary',
    supportEmail: 'partnersupport@ouraring.com',
    fetchDataRoutes: ['/account'],
    publicRoutes: PUBLIC_ROUTES,
  },
  //add more configs for each "tenant" or "partner"...
};
