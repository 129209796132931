import { GetOrdersResponse } from '@/app/utils/getOrders';
import { SubmitOrderResponse } from '@/app/utils/submitOrder';
import { ValidateEmailResponse } from '@/app/utils/validateEmail';
import { address as shippingAddressMock } from './address';

const mockDate = new Date().toISOString();

type Mock = {
  email: ValidateEmailResponse;
  submit: SubmitOrderResponse;
  getOrders: GetOrdersResponse;
};

const validateEmailMocks = {
  VALID_USER: {
    netsuiteOrderId: 'SO-123456',
    netsuiteInternalId: '1234567',
    shippingAddress: shippingAddressMock,
    emailToken: 'test-email-token',
    orderComplete: false,
    eligible: true,
  },
  INVALID_USER: {
    error: { message: 'error' },
  },
  ORDER_COMPLETED: {
    netsuiteOrderId: 'SO-123456',
    netsuiteInternalId: '1234567',
    shippingAddress: shippingAddressMock,
    skus: ['NT-JZ90-54144-06'],
    emailToken: 'test-email-token',
    orderComplete: true,
    eligible: true,
  },
};

const submitMocks = {
  SUBMIT_SUCCESS: {
    status: 'success',
    error: null,
  },
  SUBMIT_ERROR: {
    error: { message: 'Error' },
  },
};

const getOrdersMocks = {
  MULTIPLE_ORDERS: [
    {
      salesOrderId: '136db711-ce67-4927-9c48-4b07444d31c8',
      netsuiteOrderId: 'SO-123456',
      lineItems: [
        {
          originalSku: 'JZ90-4300',
          quantity: 1,
        },
        {
          originalSku: 'JZ90-4300',
          quantity: 1,
        },
        {
          originalSku: 'JZSE-0167',
          quantity: 1,
        },
      ],
      shippingAddress: shippingAddressMock,
      orderDate: mockDate,
    },
    {
      salesOrderId: '136db711-ce67-4927-9c48-4b07444d31c8',
      netsuiteOrderId: 'SO-123457',
      lineItems: [
        {
          originalSku: 'JZ90-4300',
          quantity: 1,
        },
        {
          originalSku: 'JZSE-0167',
          quantity: 1,
        },
      ],
      shippingAddress: shippingAddressMock,
      orderDate: mockDate,
    },
  ],
  SINGLE_ORDER: [
    {
      salesOrderId: '136db711-ce67-4927-9c48-4b07444d31c8',
      netsuiteOrderId: 'SO-123456',
      lineItems: [
        {
          originalSku: 'JZ90-4300',
          quantity: 1,
        },
        {
          originalSku: 'JZSE-0167',
          quantity: 1,
        },
      ],
      shippingAddress: shippingAddressMock,
      orderDate: mockDate,
    },
  ],
  COMPLETED_ORDER: [
    {
      salesOrderId: '136db711-ce67-4927-9c48-4b07444d31c8',
      netsuiteOrderId: 'SO-123456',
      lineItems: [
        {
          originalSku: 'JZ90-4300',
          sku: 'JZ90-54217-06',
          quantity: 1,
        },
        {
          originalSku: 'JZSE-0167',
          sku: 'JZSE-0167',
          quantity: 1,
        },
      ],
      shippingAddress: shippingAddressMock,
      orderDate: mockDate,
    },
  ],
};

export const mocks: Record<string, Mock> = {
  HAPPY_PATH: {
    email: validateEmailMocks.VALID_USER,
    submit: submitMocks.SUBMIT_SUCCESS,
    getOrders: { data: undefined, error: undefined },
  },
  INVALID_USER: {
    email: validateEmailMocks.INVALID_USER,
    submit: submitMocks.SUBMIT_ERROR,
    getOrders: { data: undefined, error: undefined },
  },
  SUBMIT_ERROR: {
    email: validateEmailMocks.VALID_USER,
    submit: submitMocks.SUBMIT_ERROR,
    getOrders: { data: undefined, error: undefined },
  },
  ORDER_COMPLETE: {
    email: validateEmailMocks.ORDER_COMPLETED,
    submit: submitMocks.SUBMIT_SUCCESS,
    getOrders: { data: undefined, error: undefined },
  },
  BUSINESS_SINGLE_ORDER: {
    email: validateEmailMocks.VALID_USER,
    submit: submitMocks.SUBMIT_SUCCESS,
    getOrders: { data: getOrdersMocks.SINGLE_ORDER },
  },
  BUSINESS_MULTI_ORDER: {
    email: validateEmailMocks.VALID_USER,
    submit: submitMocks.SUBMIT_SUCCESS,
    getOrders: { data: getOrdersMocks.MULTIPLE_ORDERS },
  },
  BUSINESS_ORDER_COMPLETE: {
    email: validateEmailMocks.VALID_USER,
    submit: submitMocks.SUBMIT_SUCCESS,
    getOrders: { data: getOrdersMocks.COMPLETED_ORDER },
  },
};
