'use client';

import { type ReactNode, useState } from 'react';
import ReactCarousel from 'react-multi-carousel';

import { RESPONSIVE_DATA } from '@/app/const';

import CarouselButtons from './CarouselButtons';
import CarouselImage, { type CarouselImageProps } from './CarouselImage';
import { ChangeSlide, ImageCarouselImage } from './types';

export interface ImageCarouselProps {
  readonly images?: ImageCarouselImage[];
}

export const ImageCarousel = ({ images }: ImageCarouselProps): ReactNode => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  if (!images?.length) {
    return null;
  }

  const handleChange: ChangeSlide = (nextSlide) => setActiveSlide(nextSlide);

  return (
    <div data-testid="image-carousel">
      <ReactCarousel
        arrows={false}
        beforeChange={handleChange}
        containerClass="flex gap-4 overflow-hidden"
        customButtonGroup={
          <CarouselButtons images={images} onChange={handleChange} />
        }
        renderButtonGroupOutside={true}
        responsive={RESPONSIVE_DATA}
        sliderClass="max-h-96 bg-white md:bg-transparent"
      >
        {images.map(({ alt, src = '' }: CarouselImageProps, idx) => (
          <CarouselImage
            key={src + idx}
            active={activeSlide === idx}
            alt={alt}
            priority={idx === 0}
            src={src}
          />
        ))}
      </ReactCarousel>
    </div>
  );
};

export default ImageCarousel;
