import { eraseCookie } from '@jouzen/ecom-utils';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import SubmitButton from '../SubmitButton';

const NotFound = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const t = useTranslations();
  const resetEmailForm = () => {
    setLoading(true);
    eraseCookie('session');

    //needed to ensure the auth is reinstantiated
    window.location.replace('/');
  };

  return (
    <SubmitButton
      loading={loading}
      onClick={() => resetEmailForm()}
      full
      type="submit"
    >
      {t('my_account_another_email')}
    </SubmitButton>
  );
};
export default NotFound;
