import { Product, ProductVariant } from '@/queries/types/graphql';

const findProductVariant = (
  product: Product,
  sku: string | undefined,
): ProductVariant | undefined => {
  return (
    product?.variants?.find((variant) => variant?.skuCode === sku) ?? undefined
  );
};

export default findProductVariant;
