'use client';

import { Loading } from '@jouzen/ecom-components';

const LoadingScreen = () => (
  <Loading
    data-testid="loading-screen"
    className="z-[201] bg-gray-100"
    fullScreen
    svgClassName="w-40"
  />
);

export default LoadingScreen;
