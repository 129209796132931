'use client';

import {
  ButtonV3 as Button,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { Dispatch, SetStateAction } from 'react';

import { box } from '@/app/styles/ui';
import type { LineItem, Order } from '@/types';

import OrderInfo from '../OrderInfo';
import OrderItems, { OrderLineItem } from '../OrderItems';

export interface OrderDisplayProps {
  readonly className?: string;
  readonly isActive: boolean;
  readonly isComplete: Order['orderComplete'];
  readonly lineItems: LineItem[];
  readonly orderId: Order['netsuiteOrderId'];
  readonly shippingAddress: Order['shippingAddress'];
  readonly orderDate: Order['orderDate'];
  readonly onSelectOrder: (order: Order) => void;
  readonly onSetActive: Dispatch<SetStateAction<boolean>>;
}

const OrderDisplay = ({
  className,
  isActive,
  isComplete,
  lineItems,
  orderId,
  shippingAddress,
  orderDate,
  onSelectOrder,
  onSetActive,
}: OrderDisplayProps): JSX.Element | null => {
  const t = useTranslations();

  const styles = {
    box: box(),
    thirdCol: 'flex items-center lg:justify-end lg:col-span-1 lg:row-start-1',
  };

  return (
    <div
      data-testid="order-display"
      className={cx(styles.box, 'flex flex-col gap-4', className)}
    >
      <div
        className={cx('w-full grid gap-4', {
          'border-b border-gray-300 pb-8': isActive,
        })}
      >
        <OrderInfo
          className="lg:col-span-2 lg:row-start-1"
          address={shippingAddress}
          details={{
            number: orderId!,
            date: new Date(orderDate!),
            quantity: lineItems?.length ?? 0,
          }}
        />

        {isActive && (
          <div className={styles.thirdCol}>
            {isComplete && (
              <Typography
                data-testid="order-display-completed"
                className="rounded bg-sandstone-200 px-3 py-1 uppercase tracking-wider"
                variant="eyebrow-small"
                weight="bold"
              >
                {t('eop_home_orders_page_status_completed')}
              </Typography>
            )}
          </div>
        )}

        {!isActive && (
          <div className={styles.thirdCol}>
            <Button
              onClick={onSetActive}
              data-testid="order-display-view-order-button"
            >
              {t('eop_view_order_button')}
            </Button>
          </div>
        )}
      </div>

      {isActive && isComplete && <OrderItems lineItems={lineItems!} />}
      {isActive && !isComplete && (
        <div className="grid w-full gap-4">
          <OrderLineItem
            className="w-full lg:col-span-2 lg:row-start-1"
            lineItem={{
              ...lineItems[0],
              description: orderId
                ? t('eop_home_orders_page_status_pending_selections')
                : t('eop_home_orders_page_status_order_processing'),
            }}
          />

          <div className={styles.thirdCol}>
            <Button
              onClick={onSelectOrder}
              data-testid="order-display-select-ring-button"
              disabled={!orderId}
            >
              {lineItems.length > 1
                ? t('eop_select_rings_button')
                : t('eop_select_ring_button')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDisplay;
