import { useTranslations } from 'next-intl';

import { findImageAsset, findProductDataBySku } from '@/app/utils';
import type { Product } from '@/queries/types/graphql';
import type { OrderItem } from '@/types/Order';

import type { ImageCarouselImage } from '../../ui';

export interface Options {
  readonly lineItems?: OrderItem[];
}

export const DEFAULT_OPTIONS: Options = {
  lineItems: [],
};

const useProductCarouselImages = (
  products: Product[],
  options: Partial<Options> = {},
): ImageCarouselImage[] => {
  const t = useTranslations();
  const { lineItems } = { ...DEFAULT_OPTIONS, ...options };

  if (!products || !lineItems?.length) {
    return [];
  }

  const isMultiRing = lineItems.length > 1;

  if (isMultiRing) {
    const firstImages = products
      .map((product) => {
        const alt = t(product.titleMsgKey);
        const src =
          findImageAsset(product, 'tilt') ??
          // TODO: remove when Gen3 is extinct
          product.images?.[0]?.originalSrc;

        return { alt, src };
      })
      .filter((img) => img.src); // filter out images with no src

    return (firstImages ?? []) as ImageCarouselImage[];
  }

  const { product: selectedProduct } = findProductDataBySku(
    products,
    lineItems[0].sku,
  );
  const imageSources = ['tilt', 'angle', 'front'].map((alias) =>
    findImageAsset(selectedProduct!, alias),
  );
  const images = imageSources
    .filter((src) => src)
    .map((src) => ({
      alt: t(selectedProduct?.titleMsgKey),
      src,
    }));

  return (images ?? []) as ImageCarouselImage[];
};

export default useProductCarouselImages;
