import { cx } from 'class-variance-authority';

import type { LineItem } from '@/types';

import OrderLineItem from './OrderLineItem';

interface OrderItemsProps {
  readonly className?: string;
  readonly lineItems: LineItem[];
}

const OrderItems = ({ className, lineItems }: OrderItemsProps) => {
  return (
    <ol
      className={cx(className, 'flex h-full w-full flex-col justify-stretch')}
    >
      {lineItems.map((lineItem, i: number, { length }) => {
        const last = i + 1 === length;

        return (
          <li
            className={cx('flex-grow flex items-center', {
              'border-b border-gray-300 pb-5 mb-5': !last,
            })}
            key={i} // must use index because item details are often the same
          >
            <OrderLineItem lineItem={lineItem} />
          </li>
        );
      })}
    </ol>
  );
};

export default OrderItems;
