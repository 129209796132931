import { Address } from '@/types';
import { ApiError } from '@/types/Error';
import { OrderItem } from '@/types/Order';
import { logToDataDog } from '@/utils/logToDatadog';
import { ParsedSku } from '@/utils/parseSku';
import { metricIncrement } from '@/utils/reportMetrics';

import {
  LOGIN_METRIC_NAME,
  MetricStep,
} from '../components/ui/EmailForm/const';

type BaseOrder = {
  salesOrderId: string;
  netsuiteOrderId?: string;
  shippingAddress: Address;
  orderDate?: string;
};

type GetOrderLineItem = {
  skuCode: string;
  quantity?: number;
};

interface GetOrderApiResponse extends BaseOrder {
  lineItems: GetOrderLineItem[];
}

interface GetOrders extends BaseOrder {
  lineItems: OrderItem[];
}

export type GetOrdersResponse = {
  data?: GetOrders[];
  error?: ApiError | unknown;
};

export async function getOrders(
  email: string,
  emailToken: string | null,
  partner: string,
): Promise<GetOrdersResponse> {
  try {
    const resp = await fetch('/api/getOrders', {
      method: 'POST',
      body: JSON.stringify({ email, emailToken }),
    });
    const responseJson = await resp.json();
    if (responseJson?.error) {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.GET_ORDERS_ERROR,
        partner,
      });
      await logToDataDog('auth', 'EOP Get Orders error', {
        partner,
        emailToken: emailToken,
        error: responseJson?.error ?? null,
      });
      return {
        error: responseJson?.error,
      };
    } else {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.GET_ORDERS_SUCCESS,
        partner,
      });
      return {
        data: responseJson?.map((order: GetOrderApiResponse) => {
          return {
            salesOrderId: order.salesOrderId,
            netsuiteOrderId: order.netsuiteOrderId,
            lineItems: order.lineItems?.map((item: GetOrderLineItem) => {
              const parsedSku = new ParsedSku(item.skuCode);
              // Set the sku if the ring selections were completed
              const sku = parsedSku.hasSize() ? item.skuCode : undefined;
              return {
                originalSku: item.skuCode,
                quantity: item.quantity,
                sku: sku,
              };
            }),
            shippingAddress: order.shippingAddress,
            orderDate: order.orderDate,
          };
        }),
      };
    }
  } catch (error) {
    await logToDataDog('api', 'Error calling getOrders', { emailToken, error });
    return { error };
  }
}
