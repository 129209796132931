import { Product } from '@/queries/types/graphql';

const findImageAsset = (
  product: Product,
  alias: string,
): string | null | undefined => {
  if (!product) {
    return undefined;
  }

  if (!Array.isArray(product.imageAssets) || !product.imageAssets.length) {
    return undefined;
  }

  return product.imageAssets.find((img) => img?.alias === alias)?.src;
};

export default findImageAsset;
