import { Address } from '@/types';
import { ApiError } from '@/types/Error';
import { logToDataDog } from '@/utils/logToDatadog';
import { ParsedSku } from '@/utils/parseSku';
import { metricIncrement } from '@/utils/reportMetrics';

import {
  LOGIN_METRIC_NAME,
  MetricStep,
} from '../components/ui/EmailForm/const';

export type ValidateEmailResponse = {
  error?: ApiError | unknown;
  eligible?: boolean;
  netsuiteOrderId?: string;
  netsuiteInternalId?: string;
  orderComplete?: boolean;
  skus?: string[];
  shippingAddress?: Address;
};

export async function validateEmail(
  email: string,
  emailToken: string | null,
  partner: string,
): Promise<ValidateEmailResponse> {
  try {
    const resp = await fetch('/api/validateEmail', {
      method: 'POST',
      body: JSON.stringify({ email, emailToken }),
    });
    const responseJson = await resp.json();
    if (
      responseJson?.eligible &&
      responseJson?.netsuiteInternalId &&
      responseJson?.shippingAddress
    ) {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.EMAIL_VALIDATION_SUCCESS,
        partner,
      });
      return {
        ...responseJson,
        lineItems: responseJson.skus?.length
          ? responseJson.skus.map((sku: string) => ({
              sku: new ParsedSku(sku).withoutEnterpriseCode(),
              quantity: 1,
            }))
          : [],
        agreesToShare: responseJson.orderComplete ? false : undefined,
        orderComplete: responseJson.orderComplete,
      };
    } else {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.EMAIL_VALIDATION_ERROR,
        partner,
      });
      await logToDataDog('auth', 'EOP Email Validation error', {
        partner,
        emailToken: emailToken,
        error: responseJson?.error ?? null,
      });
      return { error: responseJson?.error };
    }
  } catch (error) {
    await logToDataDog('api', 'Error calling validateEmail', {
      emailToken,
      error,
    });
    return { error };
  }
}
