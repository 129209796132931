/* Fetches & validates data for a session*/

import { getCookie } from '@jouzen/ecom-utils';

import { PartnerConfig } from '@/configs/partner';
import { Account, Order } from '@/types';
import { ApiError } from '@/types/Error';

import { mocks } from '../../../mocks/apiMocks';
import { getOrders } from './getOrders';
import { validateEmail } from './validateEmail';

interface FetchSessionResponse {
  data?: {
    order?: Order;
    account?: Account;
  };
  error?: ApiError | unknown;
}

export const fetchSessionData = async (
  email: string,
  emailToken: string | null,
  partner: string,
  partnerConfig: PartnerConfig,
): Promise<FetchSessionResponse> => {
  try {
    const MOCK_DATA = getCookie('mock')?.toUpperCase();

    if (partnerConfig.validateUser) {
      const response = MOCK_DATA
        ? mocks[MOCK_DATA].email
        : await validateEmail(email, emailToken, partner);

      if (response?.error) return { error: response?.error };
      else return { data: { order: response } };
    }
    if (partnerConfig.getOrders) {
      const response = MOCK_DATA
        ? mocks[MOCK_DATA].getOrders
        : await getOrders(email, emailToken, partner);

      if (response?.error) return { error: response.error };
      else return { data: { account: { orders: response.data } } };
    } else return {};
  } catch (error) {
    return { error };
  }
};
