import { OrderItem } from '@/types/Order';

import { MultiRingItem } from './RingItem';

const updateItems = (
  itemsList: MultiRingItem[],
  updatedItem: MultiRingItem,
): OrderItem[] =>
  itemsList
    .map((listItem) => {
      if (listItem.id === updatedItem.id) {
        return updatedItem;
      }
      return listItem;
    })
    .map(({ id: _id, ...rest }) => rest);

export default updateItems;
