import { Product } from '@/queries/types/graphql';

import getProductFinish from './getProductFinish';

const findSku = (
  products: Product[],
  finish: string,
  size?: string,
): string | undefined => {
  const selectedProduct = products.find(
    (product) => getProductFinish(product) === finish,
  );

  if (!selectedProduct) {
    return;
  }

  const selectedVariant =
    selectedProduct?.variants?.find((variant) =>
      variant?.selectedOptions.find(
        (opt) => opt?.name === 'Size' && opt.value === size,
      ),
    ) ?? selectedProduct?.variants?.[0];

  return selectedVariant?.skuCode;
};

export default findSku;
