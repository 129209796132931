import {
  FormControl,
  type FormControlChangeEvent,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, useState } from 'react';

import { findSku } from '@/app/utils';
import { Product } from '@/queries/types/graphql';
import { OrderItem } from '@/types/Order';

import Image from '../../Image';
import { FinishOption } from '../types';
import useProductOptionData from '../useProductOptionsData';

type Trait = string | undefined;

export interface MultiRingItem extends OrderItem {
  id: string | number;
}

export interface RingItemProps {
  readonly item: MultiRingItem;
  readonly products: Product[];
  readonly onChange: (updatedItem: MultiRingItem) => void;
}

const RingItem = ({ item, products, onChange }: RingItemProps): JSX.Element => {
  const t = useTranslations();

  const { finishOptions, sizeOptions } = useProductOptionData(products);
  const [finish, setFinish] = useState<Trait>();
  const [size, setSize] = useState<Trait>();

  const handleChange = useCallback(
    (newFinish: Trait, newSize: Trait) => {
      const newSku = newFinish
        ? findSku(products, newFinish, newSize)
        : item.originalSku;

      onChange({ ...item, sku: newSku ?? item.originalSku });
    },
    [item, onChange, products],
  );

  const handleFinishChange = (e: FormControlChangeEvent) => {
    const newFinish = e.target.value;
    handleChange(newFinish, size);
    setFinish(newFinish);
  };

  const handleSizeChange = (e: FormControlChangeEvent) => {
    const newSize = e.target.value;
    handleChange(finish, newSize);
    setSize(newSize);
  };

  const img = useMemo(() => {
    const { imgSrc, label }: FinishOption | undefined =
      finishOptions.find((opt) => opt.value === finish) ??
      finishOptions[0] ??
      {};

    return {
      src: imgSrc,
      alt: label,
    };
  }, [finish, finishOptions]);

  const styles = {
    select: 'min-w-32 shrink-0 pb-2',
  };

  return (
    <div
      data-testid="product-select-ring-item"
      className="flex w-full items-center lg:gap-2"
    >
      {img.src && (
        <div
          className={cx('shrink-0 hidden sm:block', { 'opacity-40': !finish })}
        >
          <Image width="96" height="96" src={img.src} alt={img.alt} />
        </div>
      )}

      <div className="grow gap-4 lg:flex">
        <FormControl
          hideError
          name="finish"
          control="Select"
          className={cx(styles.select, 'grow')}
          options={finishOptions}
          label={t('step_finish_title')}
          onChange={handleFinishChange}
          value={finish!}
        />

        <FormControl
          hideError
          name="size"
          control="Select"
          className={styles.select}
          options={sizeOptions}
          label={t('step_size_title')}
          onChange={handleSizeChange}
          value={size!}
        />
      </div>
    </div>
  );
};

export default RingItem;
