'use client';

import {
  GridItem,
  LayoutGrid,
  Loading,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';

import { box, page } from '@/app/styles/ui';
import { config } from '@/configs/partner';
import type { Address, LineItem } from '@/types';

import {
  HelpEmailLink,
  OrderInfo,
  OrderItems,
  SectionHeading,
  Svg,
} from '../../ui';
import Section from '../Section';

interface SummaryLayoutProps {
  readonly address: Address;
  readonly email?: string;
  readonly lineItems: LineItem[];
  readonly orderId?: string;
  readonly isLoading: boolean;
  readonly additionalInfo?: ReactNode;
  readonly partner: string;
}

const SummaryLayout = ({
  address,
  email,
  lineItems,
  orderId,
  isLoading,
  additionalInfo,
  partner,
}: SummaryLayoutProps): JSX.Element => {
  const t = useTranslations();

  // only populate details if orderId is provided
  const orderDetails = orderId
    ? {
        number: orderId,
        date: new Date(),
        quantity: lineItems?.length ?? 0,
      }
    : undefined;

  const styles = {
    box: box(),
    page: page(),
  };

  const helpEmail = config[partner].supportEmail;

  return (
    <div>
      <LayoutGrid className={styles.page}>
        <GridItem
          colEnd={{ sm: 'main' }}
          colStart={{ sm: 'main' }}
          className="flex items-center gap-4 rounded-lg border border-gray-300 p-4 md:px-8 md:py-6"
        >
          <div className="shrink-0">
            <Svg name="green-circle-check" />
          </div>

          <SectionHeading Element="h2">
            {t('eop_order_confirmation_page_success_text')}
          </SectionHeading>
        </GridItem>
      </LayoutGrid>

      <Section border title={t('eop_order_confirmation_page_title')}>
        <div
          data-testid="order-display"
          className={cx(styles.box, 'flex flex-col gap-4')}
        >
          <OrderInfo
            className="border-b border-gray-300 pb-6 lg:col-span-2 lg:row-start-1"
            address={address}
            details={orderDetails}
            email={email}
          />

          {isLoading ? (
            <Loading className="my-4" />
          ) : (
            <OrderItems lineItems={lineItems} />
          )}
        </div>
      </Section>

      <Section border title={t('eop_order_confirmation_our_mission')}>
        <div className="flex flex-col gap-8">
          <Typography Element="section" variant="body-large">
            {t('eop_order_confirmation_page_message')}
          </Typography>

          {additionalInfo}
        </div>
      </Section>

      <Section title={t('eop_need_additional_help_title')}>
        <div className="flex h-full items-center">
          <HelpEmailLink helpEmail={helpEmail} />
        </div>
      </Section>
    </div>
  );
};

export default SummaryLayout;
