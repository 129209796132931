import { Product, ProductVariant } from '@/queries/types/graphql';

const findAllVariants = (
  products: Product[] = [],
  sku?: string,
): ProductVariant[] => {
  if (sku) {
    const selectedProduct = products.find((product) =>
      product.variants?.some((variant) => variant?.skuCode === sku),
    );

    return (selectedProduct?.variants ?? []) as ProductVariant[];
  }

  return products
    .map((product) => (product.variants ?? []) as ProductVariant[])
    .flat()
    .filter((variant) => variant);
};

export default findAllVariants;
