import { cx } from 'class-variance-authority';

import Image, { type ImageProps } from '../Image';

export interface CarouselImageProps extends ImageProps {
  readonly active?: boolean;
}

const CarouselImage = ({
  active: isActive,
  alt,
  priority,
  src,
}: CarouselImageProps): JSX.Element => {
  return (
    <div
      data-testid="image-carousel-image"
      className={cx(
        'aspect-square mx-auto', // stops "flicker" while replacing image
        'h-full max-h-48 md:max-h-96',
        'bg-white rounded-lg overflow-hidden',
        'flex justify-center items-center',
        'transition-opacity duration-300',
        {
          'opacity-0': !isActive,
          'opacity-100': isActive,
        },
      )}
    >
      <Image
        key={alt}
        alt={alt}
        className="max-h-60 w-auto object-contain md:max-h-96"
        draggable="false"
        height={512}
        priority={priority}
        quality={65}
        src={src}
        width={512}
      />
    </div>
  );
};

export default CarouselImage;
