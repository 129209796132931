'use client';

import { useTranslations } from 'next-intl';
import { PropsWithChildren, type ReactNode, useMemo, useState } from 'react';

import { EventType } from '@/analytics/types';
import { findProductDataBySku } from '@/app/utils';
import { useAppDispatch } from '@/lib/hooks';
import { updateLineItems } from '@/lib/order/orderSlice';
import type { Product } from '@/queries/types/graphql';
import { OrderItem } from '@/types/Order';

import SubmitButton from '../SubmitButton';
import MultiRing from './MultiRing';
import SingleRing from './SingleRing';

export interface ProductSelectProps {
  readonly products: Product[];
  readonly lineItems?: OrderItem[];
  readonly submitText: ReactNode;
  readonly enableSubmit?: boolean;
  readonly onSelect: (items: OrderItem[]) => void;
  //onSubmit function to handle API call on submit
  readonly onSubmit: () => void;
}

const ProductSelect = ({
  products,
  lineItems = [],
  submitText,
  children,
  enableSubmit,
  onSelect,
  onSubmit,
}: PropsWithChildren<ProductSelectProps>): JSX.Element => {
  const dispatch = useAppDispatch();
  const t = useTranslations();

  const [sizeError, setSizeError] = useState<string>('');

  // ensure every lineItem has a finish & size selected
  const isComplete = useMemo(
    () =>
      lineItems.every(({ sku }) => {
        const { finish, size } = findProductDataBySku(products, sku);
        return Boolean(finish) && Boolean(size) && size !== 'Size later';
      }),
    [lineItems, products],
  );

  const handleSubmitClick = async () => {
    const action = lineItems?.length > 1 ? 'submit size' : 'submit sizes';
    await window.analytics.track(EventType.CTAClicked, {
      cta: submitText,
      action,
      location: 'body',
      path: '/select',
    });

    if (isComplete) {
      dispatch(updateLineItems(lineItems));
      onSubmit();
    } else {
      setSizeError(t('eop_select_size_error'));
    }
  };

  const handleSelect = (items: ProductSelectProps['lineItems']) => {
    onSelect(items!);
  };

  const isMultiRing = useMemo(
    () => lineItems?.length && lineItems.length > 1,
    [lineItems?.length],
  );

  const styles = {
    wrap: 'flex flex-col gap-12',
  };

  return (
    <div data-testid="product-select" className={styles.wrap}>
      {isMultiRing ? (
        <MultiRing
          products={products}
          lineItems={lineItems}
          onChange={handleSelect}
        />
      ) : (
        <SingleRing
          className={styles.wrap}
          products={products}
          selectedSku={lineItems[0]?.sku}
          onSelect={(sku: OrderItem['sku']) => {
            setSizeError('');
            handleSelect([{ ...lineItems[0], sku }]);
          }}
          sizeError={sizeError}
        />
      )}

      {children}

      <SubmitButton
        disabled={!(enableSubmit || isComplete)}
        onClick={handleSubmitClick}
      >
        {submitText}
      </SubmitButton>
    </div>
  );
};

export default ProductSelect;
