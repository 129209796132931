import { cva } from 'class-variance-authority';

const box = cva('rounded-lg bg-white', {
  variants: {
    p: {
      none: '',
      undefined: 'p-6',
    },
  },
});

export default box;
