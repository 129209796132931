import { CircularFillCheckmarkIcon as Checkmark } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';

import { optionButton } from '@/app/styles/ui';

import Image from '../../Image';
import { FinishOption } from '../types';

export interface FinishOptionsProps {
  readonly onChange: (value: string) => void;
  readonly options: FinishOption[];
  readonly selected: string;
}

export const FinishOptions = ({
  onChange,
  options,
  selected,
}: FinishOptionsProps): JSX.Element => {
  return (
    <ul
      aria-labelledby="finish-options-heading"
      aria-required="true"
      className="flex flex-wrap gap-4"
      role="radiogroup"
      data-testid="finish-options"
    >
      {options.map(({ imgSrc, label, value }) => {
        const checked = selected === value;

        return (
          <li key={value} className="h-16 w-full grow lg:w-1">
            <label
              className={cx(
                optionButton({ checked }),
                'pr-6 flex gap-2 relative',
                { 'pl-8': !imgSrc },
              )}
            >
              <input
                checked={checked}
                className="sr-only"
                name="finish"
                onChange={() => onChange(value)}
                type="radio"
                value={value}
                aria-checked={checked}
              />

              {imgSrc && (
                <div className="relative h-full w-20 shrink-0 grow-0 overflow-hidden">
                  <Image
                    className="absolute -left-16 -top-10 size-40 max-w-none"
                    height="160"
                    priority
                    src={imgSrc}
                    width="160"
                  />
                </div>
              )}

              <span className="grow">{label}</span>

              {checked && (
                <span className="ml-auto shrink-0 grow-0">
                  <Checkmark />
                </span>
              )}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

export default FinishOptions;
