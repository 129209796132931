import {
  BodyLinkV3 as BodyLink,
  ModalV3 as Modal,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { MessageKey } from '@/types';

export const SizeGuide = (): JSX.Element => {
  const t = useTranslations();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div>
      <p className="text-blue-100">
        <BodyLink color="inherit" onClick={() => setModalOpen(true)}>
          {t('size_guide')}
        </BodyLink>
      </p>

      <Modal
        isOpen={modalOpen}
        onOpenChange={() => setModalOpen(false)}
        rounded
        size="small"
        variant="lightest"
      >
        <div className="flex flex-col gap-6">
          <Typography Element="h2" variant="h4">
            {t('eop_order_selections_page_modal_title')}
          </Typography>

          <Section
            title="eop_order_selections_page_modal_sizing_tip_choose_finger"
            ps={[
              'eop_order_selections_page_modal_sizing_tip_choose_finger_description',
            ]}
          />

          <Section
            title="eop_order_selections_page_modal_sizing_tip_test_fit"
            ps={[
              'eop_order_selections_page_modal_sizing_tip_test_fit_description_1',
              'eop_order_selections_page_modal_sizing_tip_test_fit_description_2',
            ]}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SizeGuide;

function Section({
  title,
  ps,
}: {
  readonly title: MessageKey;
  readonly ps: MessageKey[];
}): JSX.Element {
  const t = useTranslations();

  return (
    <section className="flex flex-col gap-2">
      <Typography Element="h3" variant="h5" color="gray">
        {t(title)}
      </Typography>

      {ps.map((msg) => (
        <p key={msg}>{t(msg)}</p>
      ))}
    </section>
  );
}
