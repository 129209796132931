'use client';
/*
 *  Copyright 2022 Curity AB
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

import { InitializationError } from '@curity/identityserver-haapi-web-driver';
import { setCookie } from '@jouzen/ecom-utils';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useRouter } from '@/i18n/routing';
import { useAppDispatch } from '@/lib/hooks';
import { AuthorizationStep } from '@/types/AuthorizationSteps';
import haapiFetch from '@/utils/auth/haapiFetch';
import { OidcClient } from '@/utils/auth/oidcClient';
import { decodeTokenToEmail } from '@/utils/decodeToken';
import { logToDataDog } from '@/utils/logToDatadog';
import { metricIncrement } from '@/utils/reportMetrics';

import { config } from '../../configs/partner';
import {
  LOGIN_METRIC_NAME,
  MetricStep,
} from '../components/ui/EmailForm/const';
import { getEmailToken } from '../utils/emailTokenHelper';
import { handleNewSession } from '../utils/handleNewSession';

interface Step {
  name?: AuthorizationStep | null;
  haapiResponse?: any;
  inputProblem?: any;
  problem?: any;
}

interface RequestInit {
  method?: string;
  body?: URLSearchParams | Record<string, string> | string | null;
  credentials?: RequestCredentials;
  headers?: HeadersInit;
}

export interface AuthProcessor {
  isLoading: boolean;
  step: Step;
  error: string | null;
  clickLink: (url: string) => Promise<void>;
  setStep: Dispatch<SetStateAction<Step>>;
  submitForm: (
    formState: null | undefined,
    url: string,
    method: string | undefined,
  ) => Promise<void>;
}

export default function useAuthProcessor(partner: string) {
  const dispatch = useAppDispatch();
  const [oidcClient] = useState(new OidcClient());
  const [step, setStep] = useState<Step>({
    name: null,
    haapiResponse: null,
    inputProblem: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { push } = useRouter();
  const [error, setError] = useState<string | null>(null);
  const partnerConfig = config[partner];

  useEffect(() => {
    async function startAuth() {
      await startAuthorization();
    }
    startAuth();
  }, []);

  useEffect(() => {
    async function completeAuthorization() {
      if (step.name === AuthorizationStep.COMPLETE) {
        setIsLoading(true);
        await metricIncrement(LOGIN_METRIC_NAME, {
          step: MetricStep.OTP_SUCCESS,
          partner,
        });

        const tokens = await oidcClient.fetchTokens(
          step.haapiResponse.properties.code,
        );

        const email = decodeTokenToEmail(tokens.id_token);

        setCookie('session', tokens.id_token, tokens.expires_in);

        await handleNewSession(email, partner, partnerConfig, dispatch, push);
      }
    }
    completeAuthorization();
  }, [partner, step, oidcClient, push, partnerConfig, dispatch]);

  const callHaapi = useCallback(
    async (url: URL | string, method = 'GET', data = null): Promise<void> => {
      let finalUrl = url;
      const init: RequestInit = { method };
      if (data) {
        if (method === 'POST' || method === 'PUT') {
          init.body = data;
        } else {
          finalUrl = new URL(url);
          // @ts-ignore
          data?.forEach((value: any, key: any) => {
            // @ts-ignore
            finalUrl.searchParams.set(key, value);
          });
        }
      }

      const emailToken = getEmailToken();

      try {
        // @ts-ignore
        const response = await haapiFetch(finalUrl, init);
        const haapiResponse = await response.json();
        setStep({ name: AuthorizationStep.PROCESS_RESULT, haapiResponse });
      } catch (error) {
        console.log(error);
        await logToDataDog('auth', 'EOP authorization error', {
          partner,
          emailToken,
          error: JSON.stringify(error),
        });

        if (error instanceof InitializationError) {
          setStep({
            name: AuthorizationStep.ERROR,
            problem: { title: 'Error initializing with HAAPI Authentication' },
          });
        } else {
          setStep({
            name: AuthorizationStep.ERROR,
            problem: {
              title: error?.toString() || 'Error calling HAAPI client',
            },
          });
        }
      }
    },
    [partner],
  );

  const startAuthorization = useCallback(async (): Promise<void> => {
    setStep({ name: AuthorizationStep.LOADING, haapiResponse: null });
    setIsLoading(true);

    const url = await oidcClient.getAuthorizationUrl();
    await callHaapi(url);
  }, [callHaapi, oidcClient]);

  const processHaapiResult = useCallback(async (): Promise<void> => {
    setError(null);
    setIsLoading(false);
    const { haapiResponse } = step;

    switch (haapiResponse.type) {
      case AuthorizationStep.REDIRECT:
        await processRedirect();
        break;
      case AuthorizationStep.REGISTRATION:
        setStep({ name: AuthorizationStep.REGISTRATION, haapiResponse });
        break;
      case AuthorizationStep.AUTHENTICATION:
        setStep({ name: AuthorizationStep.AUTHENTICATION, haapiResponse });
        break;
      case 'https://curity.se/problems/incorrect-credentials':
        setStep({
          name: step.haapiResponse.type,
          haapiResponse: haapiResponse,
          problem: haapiResponse,
        });
        setError(haapiResponse?.title ?? step.problem.title);
        await logToDataDog(
          'auth',
          'User entered incorrect credentials',
          {
            error: haapiResponse?.title ?? step.problem.title,
            details: haapiResponse,
            emailToken: getEmailToken(),
          },
          'warn',
        );
        break;
      case 'oauth-authorization-response':
        setStep({ name: AuthorizationStep.COMPLETE, haapiResponse });
        break;
      case 'https://curity.se/problems/invalid-input':
        setStep({
          name: step.haapiResponse.type,
          haapiResponse: haapiResponse,
          inputProblem: haapiResponse,
        });
        setError(haapiResponse?.title ?? step.problem.title);
        await logToDataDog(
          'auth',
          'User entered invalid input',
          {
            error: haapiResponse?.title ?? step.problem.title,
            details: haapiResponse,
            emailToken: getEmailToken(),
          },
          'warn',
        );
        break;
      case 'polling-step':
        setStep({ name: step.haapiResponse.type, haapiResponse });
        break;
      default:
        setStep({ name: AuthorizationStep.UNKNOWN, haapiResponse });
    }
  }, [step]);

  const submitForm = async (
    formState: null | undefined,
    url: string,
    method: string | undefined,
  ) => {
    setIsLoading(true);
    await callHaapi(url, method, formState);
  };

  const clickLink = async (url: string | URL) => await callHaapi(url);

  const processRedirect = async () => {
    const action = step.haapiResponse.actions[0];

    if (action.template === 'form' && action.kind === 'redirect') {
      await callHaapi(
        action.model.href,
        action.model.method,
        // @ts-ignore
        getRedirectBody(action.model.fields),
      );

      return;
    }

    setStep({
      name: AuthorizationStep.UNKNOWN,
      haapiResponse: step.haapiResponse,
    });
  };

  const processPolling = async () => {
    const action = step.haapiResponse.actions[0];

    // Polling every 3 seconds
    await new Promise((r) => setTimeout(r, 3000));

    if (action.template === 'form' && action.kind === 'poll') {
      await callHaapi(action.model.href, action.model.method);

      return;
    }

    if (action.template === 'form' && action.kind === 'redirect') {
      await callHaapi(
        action.model.href,
        action.model.method,
        // @ts-ignore
        getRedirectBody(action.model.fields),
      );

      return;
    }

    setStep({
      name: AuthorizationStep.UNKNOWN,
      haapiResponse: step.haapiResponse,
    });
  };

  const processContinue = async () => {
    const continueAction =
      step.haapiResponse.actions[0].model.continueActions[0];

    if (
      continueAction &&
      continueAction.template === 'form' &&
      continueAction.kind === 'continue'
    ) {
      await callHaapi(
        continueAction.model.href,
        continueAction.model.method,
        //@ts-ignore
        getRedirectBody(continueAction.model.fields),
      );

      return;
    }

    setStep({
      name: AuthorizationStep.UNKNOWN,
      haapiResponse: step.haapiResponse,
    });
  };

  const launchExternalBrowser = async () => {
    const url =
      step.haapiResponse.actions[0].model.arguments.href +
      '&for_origin=' +
      window.location;
    const popup = window.open(url);

    window.addEventListener('message', async (event) => {
      if (event.source !== popup) {
        return;
      }

      const continueAction =
        step.haapiResponse.actions[0].model.continueActions[0];

      await callHaapi(
        continueAction.model.href,
        continueAction.model.method,
        //@ts-ignore
        new URLSearchParams({ _resume_nonce: event.data }),
      );

      setTimeout(() => popup?.close(), 3000);
    });
  };

  useEffect(() => {
    async function handleSteps() {
      switch (step.name) {
        case AuthorizationStep.PROCESS_RESULT:
          processHaapiResult();
          break;
        case AuthorizationStep.CONTINUE_REDIRECT:
          processRedirect();
          break;
        case AuthorizationStep.POLLING:
          processPolling();
          break;
        case AuthorizationStep.CONTINUE_CONTINUE:
          processContinue();
          break;
        case AuthorizationStep.EXTERNAL_BROWSER_LAUNCH:
          launchExternalBrowser();
          break;
        case AuthorizationStep.ERROR:
          setError('An error occurred. Please refresh the page and try again.');
          await logToDataDog('auth', step.problem.title, {
            partner,
            error: step.problem,
            emailToken: getEmailToken(),
          });
          break;
        default:
          break;
      }
    }
    handleSteps();
  }, [partner, step]);

  return {
    isLoading,
    step,
    error,
    clickLink,
    setStep,
    submitForm,
  };
}

const getRedirectBody = (fields: any[]) => {
  if (!fields) {
    return null;
  }

  return new URLSearchParams(fields.map((field) => [field.name, field.value]));
};
